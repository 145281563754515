<template>
  <div class="page">
    <div class="search">
      <div class="keywords bgcolor-m">
        <van-row>
          <van-col span="3" @click="back()"
            ><div class="back-btn">
              <van-icon name="arrow-left" />返回
            </div></van-col
          >
          <van-col span="21">
            <van-search
              v-model="keyword"
              :background="COLOR_M"
              placeholder="请输入搜索关键词"
              ref="keyword"
              @focus="search"
            ></van-search>
          </van-col>
        </van-row>
      </div>
      <van-row class="module">
        <van-col span="4" offset="1">搜索内容</van-col>
        <van-col span="18">
          <van-radio-group
            v-model="type"
            direction="horizontal"
            :checked-color="COLOR_M"
          >
            <van-radio name="RCT" icon-size="18">工作招聘</van-radio>
            <van-radio name="TRA" icon-size="18">证书培训</van-radio>
            <van-radio name="ERO" icon-size="18">上学咨询</van-radio>
          </van-radio-group>
        </van-col>
      </van-row>
      <van-row class="btn">
        <van-col span="12" offset="6">
          <van-button
            size="small"
            :color="COLOR_M"
            block
            icon="search"
            @click="searchRecord()"
            >立即搜索</van-button
          ></van-col
        >
      </van-row>
    </div>
    <div class="history">
      <div class="title"><van-icon name="notes-o" /> 历史记录</div>
      <div class="record">
        <div class="items">
          <!-- <van-tag class="item">建造师</van-tag
        ><van-tag class="item">建造师</van-tag> -->
        </div>
        <van-image
          width="103"
          height="103"
          :src="require('../../../assets/images/home/no-record.png')"
        ></van-image>
      </div>
    </div>
    <div class="recmd">
      <div class="title"><van-icon name="notes-o" /> 搜索推荐</div>
      <div class="content">
        <van-image
          width="103"
          height="103"
          :src="require('../../../assets/images/home/no-record.png')"
        ></van-image>
      </div>
    </div>
    <Share ref="share" />
  </div>
</template>
<script>
import { Search, Icon, Tag, Image, Field, RadioGroup, Radio } from 'vant'
import Share from '../../module/common/Share.vue'
export default {
  components: {
    Share: Share,
    [Search.name]: Search,
    [Icon.name]: Icon,
    [Tag.name]: Tag,
    [Image.name]: Image,
    [Field.name]: Field,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio
  },
  data () {
    return {
      keyword: '',
      type: 'TRA'
    }
  },
  mounted () {
    document.title = '内容搜索'
    var query = this.$route.query
    this.type = query.type
    this.$refs.keyword.focus()
    if (query.keyword !== undefined && query.keyword != null) {
      this.keyword = query.keyword
    }
    this.$refs.share.default('seller')
  },
  methods: {
    searchListener (event) {
      if (event.keyCode === 13) {
        this.searchRecord()
      }
    },
    searchRecord () {
      if (this.type === 'RCT') {
        this.$router.push({ path: '/recruit/searchSeller', query: { keyword: this.keyword } })
      } else if (this.type === 'TRA') {
        this.$router.push({ path: '/seller/home/train', query: { keyword: this.keyword } })
      } else if (this.type === 'ERO') {
        this.$router.push({ path: '/seller/home/enroll', query: { keyword: this.keyword } })
      }
    },
    back () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="less" scoped>
.search {
  .keywords {
    .back-btn {
      font-size: 14px;
      font-weight: 600;
      height: 50px;
      line-height: 50px;
      color: #fff;
    }
    .text {
      color: #fff;
    }
  }
  .text {
    color: #fff;
  }
  .module {
    margin: 10px auto;
    text-align: center;
  }
  .btn {
    margin-bottom: 10px;
  }
}
.title {
  height: 35px;
  line-height: 35px;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  padding-left: 10px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
.record {
  text-align: center;
  height: 200px;
  .items {
    margin: 5px;
    text-align: left;
    .item {
      margin-right: 3px;
    }
  }
}

.recmd {
}
</style>
